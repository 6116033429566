import generateRandomKey from "@/utils/generateRandomKey";
import actions from "@/utils/constants/action_types";
import mutations from "@/utils/constants/mutation_types";

const scopedActions = actions.attendee;
const scopedMutations = mutations.attendee;

const state = () => ({
    contactInfo: {
        emailAddress: '',
        primaryContactNumber: '',
        secondaryContactNumber: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: ''
    },
    emergencyInfo: {
        emergencyContact: '',
        emergencyPhone: ''
    },
    room: {
        roomCount: 1,
        locationPreference: [],
        roomComments: '',
    },
    familyMembers: [],
    church: '',
    donationAmount: 0,
    isTransportationNeeded: false,
    discountCode: null,
});

const getterHandlers = {
    // getFormFieldValue: (state) => (prop, parentProp) => {
    //     if (parentProp) {
    //         return state[parentProp][prop];
    //     } else {
    //         return state[prop];
    //     }
    // }
};

const actionHandlers = {
    [scopedActions.SET_DISCOUNT_CODE]({ commit }, payload) {
        commit(scopedMutations.DISCOUNT_CODE_CHANGED, payload);
    },

    [scopedActions.UPDATE_FORM_FIELD]({ commit }, payload) {
        commit(scopedMutations.FORM_FIELD_CHANGED, payload);
    },

    [scopedActions.INITIALIZE_FAMILY_MEMBERS]({ commit }, payload) {
        ['father', 'mother'].forEach(prop => {
            if (payload[prop]) {
                commit(scopedMutations.FAMILY_MEMBER_ADDED, { type: prop });
            }
        });

        ['angels', 'otherAdults', 'children'].forEach(prop => {
            const count = payload[prop] < 0 ? 0 : payload[prop];
            for (let i = 0; i < count; i++) {
                commit(scopedMutations.FAMILY_MEMBER_ADDED, { type: prop });
            }
        });
    },

    [scopedActions.ADD_FAMILY_MEMBER]({ commit }, payload) {
        commit(scopedMutations.FAMILY_MEMBER_ADDED, payload);
    },

    [scopedActions.REMOVE_FAMILY_MEMBER]({ commit }, payload) {
        commit(scopedMutations.FAMILY_MEMBER_REMOVED, payload);
    },

    [scopedActions.UPDATE_FAMILY_MEMBER_FIELD]({ commit }, payload) {
        commit(scopedMutations.FAMILY_MEMBER_FIELD_CHANGED, payload);
    },
};

const mutationHandlers = {
    [scopedMutations.DISCOUNT_CODE_CHANGED](state, payload) {
        const { discountCode } = payload;
        state.discountCode = discountCode;
    },

    [scopedMutations.FORM_FIELD_CHANGED](state, payload) {
        const { prop, value, parentProp } = payload;
        if (parentProp) {
            state[parentProp][prop] = value;
        } else {
            state[prop] = value;
        }
    },

    [scopedMutations.FAMILY_MEMBER_ADDED](state, payload) {
        const { type } = payload;
        const newMember = {
            type,
            key: generateRandomKey(),
            // addMeals: true
        };
        state.familyMembers = [...state.familyMembers, newMember];
    },

    [scopedMutations.FAMILY_MEMBER_REMOVED](state, payload) {
        const { key } = payload;
        const updatedFamilyMembers = [...state.familyMembers];
        const idx = updatedFamilyMembers.findIndex((m) => m.key === key);
        if (idx > -1) {
            updatedFamilyMembers.splice(idx, 1);
        }
        state.familyMembers = updatedFamilyMembers;
    },

    [scopedMutations.FAMILY_MEMBER_FIELD_CHANGED](state, payload) {
        const { prop, value, familyMember } = payload;
        const updatedFamilyMembers = [...state.familyMembers];
        const idx = updatedFamilyMembers.findIndex((m) => m.key === familyMember.key);
        updatedFamilyMembers[idx] = { ...updatedFamilyMembers[idx], [prop]: value };
        state.familyMembers = updatedFamilyMembers;
    },
};

export default {
    namespaced: true,
    state,
    getters: getterHandlers,
    actions: actionHandlers,
    mutations: mutationHandlers
}