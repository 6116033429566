import axios from 'axios';

import { getIdToken } from '@/service/firebase';
import { log } from "@/utils/logger";

const region = 'us-east1';
const projectId = 'arm-convention-registration';

const baseURL =
    process.env.NODE_ENV === 'production'
        ? `https://${region}-${projectId}.cloudfunctions.net/admin`
        : `http://localhost:5001/${projectId}/${region}/admin`;

const instance = axios.create({
    baseURL
});

instance.interceptors.request.use(async req => {
    log(`Intercepted request ${req.method} ${req.url}`);
    const idToken = await getIdToken();
    req.headers.authorization = `Bearer ${idToken}`;
    return req;
});

export async function processTestPayment(orderId) {
    const url = `/test-payment/${orderId}`;
    const response = await instance.post(url);
    return response.data;
}

export async function getConventions() {
    const url = `/conventions`;
    const response = await instance.get(url);
    return response.data;
}

export async function getRegistrations(conventionId) {
    const url = `/conventions/${conventionId}/registrations`;
    const response = await instance.get(url);
    return response.data;
}

export async function exportRegistrations(conventionId) {
    const url = `/conventions/${conventionId}/registrations/export`;
    const response = await instance.post(url, null, { responseType: "blob" });
    return response;
}

export async function resendVolunteerRecommendationEmail(conventionId, registrationId) {
    const url = `/conventions/${conventionId}/volunteers/${registrationId}/recommendation-email`;
    const response = await instance.post(url);
    return response;
}