<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Registration Details</p>
    </header>
    <section class="modal-card-body">
      <div>
        <p class="subtitle has-background-info-dark has-text-white p-1 mt-4">General</p>

        <p class="is-flex mt-1">
          <strong class="data-label">ID:</strong>
          <span>{{ registration.id }}</span>
        </p>

        <p class="is-flex mt-1">
          <strong class="data-label">Date:</strong>
          <span>{{ formatDateTime(registration.registeredAt) }}</span>
        </p>
      </div>

      <div>
        <p class="subtitle has-background-info-dark has-text-white p-1 mt-4">Payment Info</p>

        <p class="is-flex mt-2">
          <strong class="data-label">Total:</strong>
          <span>{{ formatAmount(registration.total) }}</span>
        </p>

        <p class="is-flex mt-2">
          <strong class="data-label">Payment:</strong>
          <span>{{ paymentAmount }}</span>
        </p>

        <p class="is-flex mt-2">
          <strong class="data-label">Discount:</strong>
          <span>{{ formatAmount(registration.discountAmount || 0) }}</span>
        </p>

        <p class="is-flex mt-2">
          <strong class="data-label">Discount Code:</strong>
          <span>{{ registration.discountCode || "" }}</span>
        </p>
      </div>

      <p class="subtitle has-background-info-dark has-text-white p-1 mt-4">Family Members</p>

      <div
        v-for="familyMember in registration.familyMembers"
        :key="familyMember.key"
      >
        <p class="subtitle has-background-success-dark has-text-white p-1 mt-4">
          {{ getFamilyMemberTitle(familyMember) }}
        </p>
        <p
          v-for="field in getFamilyMemberFields(familyMember)"
          :key="field.name"
          class="is-flex mt-2"
        >
          <strong class="data-label">{{ getFieldLabel(field) }}:</strong>
          <span>{{ getFamilyMemberFieldValue(field, familyMember) }}</span>
        </p>
      </div>

      <div v-for="(section, idx) in sections" :key="idx">
        <p class="subtitle has-background-info-dark has-text-white p-1 mt-4">
          {{ section.title }}
        </p>

        <p
          v-for="field in section.fields"
          :key="field.name"
          class="is-flex mt-2"
        >
          <strong class="data-label">{{ getFieldLabel(field) }}:</strong>
          <span>{{ getFieldValue(field, section.parentField) }}</span>
        </p>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button label="Close" @click="$emit('close')" />
    </footer>
  </div>
</template>

<script>
import startCase from "lodash/startCase";
import get from "lodash/get";
import { formatCurrency } from "@/utils/number";
import { formatFullDateTime, formatFullDate } from "@/utils/dateandtime";
import getMemberTypeLabel from "@/utils/getMemberTypeLabel";
import WizardConfig from "@/config/AttendeeWizard";
import FamilyMembersConfig from "@/config/FamilyMembers";

export default {
  name: "AdminEventAttendeesModal",

  props: {
    registration: Object,
  },

  computed: {
    paymentAmount() {
      let amount = 0;
      if (
        this.registration.paymentInfo &&
        this.registration.paymentInfo.amount
      ) {
        amount = parseFloat(this.registration.paymentInfo.amount);
      }
      return formatCurrency(amount);
    },

    sections() {
      return [
        ...WizardConfig.contactInfoStep.sections,
        ...WizardConfig.roomStep.sections,
        ...WizardConfig.additionalInfoStep.sections,
      ];
    },
  },

  methods: {
    formatAmount(value) {
      return formatCurrency(value);
    },

    formatDateTime(value) {
      return formatFullDateTime(value);
    },

    getFieldKey(prop, parentProp) {
      return parentProp ? `${parentProp}.${prop}` : prop;
    },

    getFieldValue(field, parentProp) {
      const fieldKey = this.getFieldKey(field.name, parentProp);
      const value = get(this.registration, fieldKey);
      return this.transformValue(value, field);
    },

    transformValue(value, field) {
      if (field.type === "multi-select") {
        return value ? value.join(", ") : "";
      } else if (field.type === "switch") {
        return value ? 'Yes' : 'No';
      } else if (field.type === "date") {
        return value ? formatFullDate(value) : "";
      }
      return value;
    },

    getFieldLabel(field) {
      return field.label || startCase(field.name);
    },

    getFamilyMemberFieldValue(field, familyMember) {
      const value = get(familyMember, field.name);
      return this.transformValue(value, field);
    },

    getFamilyMemberTitle(familyMember) {
      const { type } = familyMember;
      const memberTypeLabel = getMemberTypeLabel(type);
      const firstName = familyMember.firstName || "";

      return `${memberTypeLabel} - ${firstName}`;
    },

    getFamilyMemberFields(familyMember) {
      if (["father", "mother"].includes(familyMember.type)) {
        return FamilyMembersConfig.commonFields;
      } else if (familyMember.type === "children") {
        return FamilyMembersConfig.childFields;
      } else if (familyMember.type === "angels") {
        return FamilyMembersConfig.angelFields;
      } else if (familyMember.type === "otherAdults") {
        return FamilyMembersConfig.otherAdultFields;
      }
      return [];
    },
  },
};
</script>

<style scoped>
.data-label {
  flex-basis: 170px;
  /* text-align: right; */
  margin-right: 10px;
}
</style>