const actionTypes = {
    FETCH_SELECTED_CONVENTION: 'fetchSelectedConvention',
    FETCH_PRIEST_NAMES: 'fetchPriestNames',
    ACCEPT_RULES: 'acceptRules',
    ACCEPT_COVID_DISCLAIMER: 'acceptCovidDisclaimer',
    
    attendee: {
        SET_DISCOUNT_CODE: 'setDiscountCode',
        UPDATE_FORM_FIELD: 'updateFormField',
        INITIALIZE_FAMILY_MEMBERS: 'initializeFamilyMembers',
        ADD_FAMILY_MEMBER: 'addFamilyMember',
        REMOVE_FAMILY_MEMBER: 'removeFamilyMember',
        UPDATE_FAMILY_MEMBER_FIELD: 'updateFamilyMemberField',
    },

    volunteer: {
        SET_DISCOUNT_CODE: 'setDiscountCode',
        UPDATE_FORM_FIELD: 'updateFormField',
        INITIALIZE_GUESTS: 'initializeGuests',
        ADD_GUEST: 'addGuest',
        REMOVE_GUEST: 'removeGuest',
        UPDATE_GUEST_FIELD: 'updateGuestField',
    },

    admin: {
        HANDLE_LOGIN: 'handleLogin',
        HANDLE_LOGOUT: 'handleLogout',
        HANDLE_RESET_PASSWORD: 'handleResetPassword',
        HANDLE_AUTH_CHANGE: 'handleAuthChange',
        FETCH_ALL_CONVENTIONS: 'fetchAllConventions',
        FETCH_REGISTRATIONS: 'fetchRegistrations',
        EXPORT_REGISTRATIONS: 'exportRegistrations',
        RESEND_VOLUNTEER_RECOMMENDATION_EMAIL: 'resendVolunteerRecommendationEmail',
    },

};

export default actionTypes;