<template>
  <div>
    <div class="mb-4 is-flex is-justify-content-start">
      <b-button ref="addGuestButton" type="is-primary is-light" icon-left="plus-circle-outline" outlined
        @click.prevent="handleAddGuest" :disabled="guests.length == 3">Add Child</b-button>
    </div>

    <b-message type="is-info" has-icon :closable="false"><strong>Note:</strong> adding a child will automatically change
      your room type to <strong>Private</strong>.</b-message>

    <b-message type="is-danger" has-icon>
      Only children of the servants are allowed to accompany them. Anyone bringing children should call Abouna at 239-273-5029 directly <strong>PRIOR</strong> to registering.
    </b-message>

    <div v-if="guests.length == 0" class="box">
      <p class="title is-5 has-text-grey">No children added.</p>
    </div>

    <article v-for="guest in guests" :key="guest.key" class="message is-primary">
      <div class="message-header">
        {{ getVolunteerGuestTitle(guest) }}
        <button class="delete is-large" @click.prevent="confirmDelete(guest)"></button>
      </div>
      <div class="message-body has-background-white">
        <form-field v-for="field in getFields(guest)" :key="field.name" :field="field"
          :value="getFieldValue(field.name, guest)" :fieldError="getFieldError(field.name, guest)"
          @input="(value) => handleFieldChange(value, field.name, guest)" />
      </div>
    </article>

  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import get from "lodash/get";

import mixins from "@/components/mixins";

import actions from "@/utils/constants/action_types";
import { validateField } from "@/utils/validators";
import VolunteerGuestsConfig from "@/config/VolunteerGuests";
import FormField from "@/components/FormField.vue";
import VolunteerGuestsModal from "./VolunteerGuestsModal.vue";

const namespace = "volunteer";
const scopedActions = actions[namespace];

export default {
  name: "VolunteerGuestsStep",

  components: {
    FormField,
  },

  mixins: [mixins],

  props: {
    isActiveStep: Boolean,
  },

  data() {
    return {
      errors: [],
    };
  },

  watch: {
    isActiveStep: function (val) {
      if (val) {
        this.displayModalIfNecessary();
      }
    },
  },

  computed: {
    ...mapState(namespace, ["guests", "guestsInitialized"]),
  },

  methods: {
    ...mapActions(namespace, [scopedActions.ADD_GUEST, scopedActions.REMOVE_GUEST]),

    displayModalIfNecessary() {
      if (!this.guestsInitialized) {
        this.$buefy.modal.open({
          parent: this,
          component: VolunteerGuestsModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
        });
      }
    },

    getVolunteerGuestTitle(guest) {
      const { key } = guest;
      const firstName = guest.firstName || "";
      const label = 'Child';

      const index = this.guests.findIndex((item) => item.key === key);

      return firstName
        ? `${label} - ${firstName}`
        : `${label} ${index + 1}`;
    },

    getFields() {
      return VolunteerGuestsConfig.commonFields;
    },

    handleAddGuest() {
      if (this.$refs.addGuestButton.$vnode?.elm) {
        this.$refs.addGuestButton.$vnode.elm.blur();
      }
      this[scopedActions.ADD_GUEST]();
    },

    confirmDelete(guest) {
      const guestTitle = this.getVolunteerGuestTitle(guest);
      this.$buefy.dialog.confirm({
        title: `Removing ${guestTitle}`,
        message: `Are you sure you want to <b>remove ${guestTitle}</b>?`,
        confirmText: "Remove",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this[scopedActions.REMOVE_GUEST]({ key: guest.key }),
      });
    },

    validateStep() {
      const allStepFields = this.guests.flatMap((guest) => {
        const fields = this.getFields(guest);
        return fields.map((field) => {
          return {
            prop: field.name,
            value: this.getFieldValue(field.name, guest),
            guest,
          };
        });
      });

      allStepFields.forEach((f) => this.handleFieldValiation(f));
      return this.errors.length === 0;
    },

    handleFieldValiation({ prop, value, guest }) {
      const errorKey = this.getFieldKey(prop, guest);
      this.clearError(errorKey);
      const fieldConfig = this.getFieldConfig(prop);
      const error = validateField(fieldConfig, value);
      if (error) {
        this.errors.push({ key: errorKey, message: error });
      }
    },

    handleFieldChange(value, prop, guest) {
      if (typeof value == "string") {
        value = value.trim();
      }

      const payload = {
        prop,
        value,
        guest,
      };
      const actionType = `${namespace}/${actions[namespace].UPDATE_GUEST_FIELD}`;
      this.$store.dispatch(actionType, payload);
      this.handleFieldValiation(payload);
    },

    getFieldConfig(prop) {
      const field = VolunteerGuestsConfig.commonFields.find(
        (f) => f.name === prop
      );
      return field;
    },

    getFieldKey(prop, guest) {
      return `${guest.key}.${prop}`;
    },

    getFieldValue(prop, guest) {
      return get(guest, prop);
    },

    getFieldError(prop, guest) {
      const fieldKey = this.getFieldKey(prop, guest);
      const error = this.errors.find((e) => e.key === fieldKey);
      return error?.message;
    },

    clearError(key) {
      const idx = this.errors.findIndex((e) => e.key === key);
      if (idx > -1) {
        this.errors.splice(idx, 1);
      }
    },
  },
};
</script>