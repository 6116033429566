<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Add Children</p>
    </header>
    <section class="modal-card-body">
      <p class="subtitle is-4 is-spaced">
        Are you bringing any children?
      </p>
      <form-field
        v-for="field in modalFields.fields"
        :key="field.name"
        :field="field"
        v-model="options[field.name]"
      />
    </section>
    <footer class="modal-card-foot">
      <b-button label="Done" type="is-primary" @click="handleDone" />
    </footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import actions from "@/utils/constants/action_types";

import FormField from "@/components/FormField.vue";
import ModalFieldsConfig from "@/config/VolunteerGuestsModal";

const namespace = "volunteer";
const scopedActions = actions[namespace];

export default {
  name: "VolunteerGuestsModal",

  components: {
    FormField,
  },

  data() {
    return {
      options: {
        guestCount: 0,
      },
    };
  },

  computed: {
    modalFields() {
      return ModalFieldsConfig;
    },
  },

  methods: {
    ...mapActions(namespace, [scopedActions.INITIALIZE_GUESTS]),

    handleDone() {
      this[scopedActions.INITIALIZE_GUESTS](this.options);
      this.$emit("close");
    },
  },
};
</script>