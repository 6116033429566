const mixins = {
    methods: {
        notifyError(message, indefinite=true) {
            this.$buefy.notification.open({
                duration: 3000,
                message,
                position: 'is-bottom',
                type: 'is-danger',
                hasIcon: true,
                indefinite
            });
        }, 

        notifySuccess(message, indefinite=false) {
            this.$buefy.notification.open({
                duration: 3000,
                message,
                position: 'is-bottom',
                type: 'is-success',
                hasIcon: true,
                indefinite
            });
        }
    }
};

export default mixins;