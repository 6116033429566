const required = true;

const commonFields = [
    {
        name: "firstName",
        type: "text",
        required,
    },
    {
        name: "lastName",
        type: "text",
        required,
    },
    {
        name: "birthday",
        type: "date",
        disableFutureDates: true,
        required,
    },
];

export default { commonFields };