const config = {
    fields: [
        {
            name: "guestCount",
            label: "Number of Children",
            type: "counter",
            min: 0,
            max: 3,
        },
    ]
};

export default config;