import format from 'date-fns/format';
import differenceInYears from 'date-fns/differenceInYears';

export function formatDay(date) {
    return format(new Date(date), 'EEEE, MMMM d');
}

export function formatFullDate(date) {
    return format(new Date(date), 'EEE, MMM d yyyy');
}

export function formatFullDateTime(date) {
    return format(new Date(date), 'EEE, MMM d yyyy, h:mm aa');
}

export function formatTimestamp(date) {
    return format(new Date(date), 'yyyy-MM-dd_HH-mm');
}

export function diffYears(earlierDate, laterDate) {
    return differenceInYears(new Date(laterDate), new Date(earlierDate));
}