const mutationTypes = {
    SELECTED_CONVENTION_FETCHED: 'selectedConventionFetched',
    PRIEST_NAMES_FETCHED: 'priestNamesFetched',
    RULES_ACCEPTED: 'rulesAccepted',
    COVID_DISCLAIMER_ACCEPTED: 'covidDisclaimerAccepted',

    attendee: {
        DISCOUNT_CODE_CHANGED: 'discountCodeChanged',
        FORM_FIELD_CHANGED: 'formFieldChanged',
        FAMILY_MEMBER_ADDED: 'familyMemberAdded',
        FAMILY_MEMBER_REMOVED: 'familyMemberRemoved',
        FAMILY_MEMBER_FIELD_CHANGED: 'familyMemberFieldChanged',
    },

    volunteer: {
        DISCOUNT_CODE_CHANGED: 'discountCodeChanged',
        FORM_FIELD_CHANGED: 'formFieldChanged',
        GUESTS_INITIALIZED: 'guestsInitialized',
        GUEST_ADDED: 'guestAdded',
        GUEST_REMOVED: 'guestRemoved',
        GUEST_FIELD_CHANGED: 'guestFieldChanged',
    },

    admin: {
        AUTH_CHANGED: 'authChanged',
        CONVENTIONS_FETCHED: 'conventionsFetched',
        REGISTRATIONS_FETCHED: 'registrationsFetched',
    },

};

export default mutationTypes;