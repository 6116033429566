<template>
  <b-steps
    v-model="activeStep"
    :animated="true"
    :rounded="true"
    :has-navigation="true"
    label-position="bottom"
    mobile-mode="compact"
    class="step-container"
  >
    <b-step-item
      v-for="(step, idx) in steps"
      :key="step.title"
      :step="idx + 1"
      :label="step.title"
      :clickable="false"
    >
      <component
        :is="step.component"
        v-bind="step.props"
        :isActiveStep="activeStep == idx"
      />
    </b-step-item>

    <template v-if="true" #navigation="{ previous, next }">
      <b-button
        outlined
        type="is-default"
        icon-left="arrow-left"
        :disabled="previous.disabled"
        @click.prevent="previous.action"
      >
        Previous
      </b-button>
      <b-button
        v-show="activeStep < steps.length - 1"
        class="ml-1"
        type="is-primary"
        icon-right="arrow-right"
        :disabled="next.disabled"
        @click.prevent="handleForwardNavigation(next.action)"
      >
        Next
      </b-button>
    </template>
  </b-steps>
</template>

<script>
import { mapState } from "vuex";
import mixins from "@/components/mixins";
import WizardConfig from "@/config/VolunteerWizard";
import WizardStep from "@/components/WizardStep.vue";
import VolunteerGuestsStep from "./VolunteerGuestsStep.vue";
import ReviewAndPay from "@/components/ReviewAndPay.vue";
import RulesModal from "@/components/RulesModal.vue";
import CovidDisclaimerModal from "@/components/CovidDisclaimerModal.vue";

const namespace = "volunteer";

export default {
  name: "VolunteerWizard",

  components: {
    WizardStep,
    ReviewAndPay,
  },

  mixins: [mixins],

  provide() {
    return {
      namespace,
    };
  },

  data() {
    return {
      activeStep: 0,
    };
  },

  watch: {
    activeStep: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },

  computed: {
    ...mapState({
      rulesAccepted: (state) => state.global.rulesAccepted,
    }),

    steps() {
      const buildFormStep = (stepConfig) => ({
        title: stepConfig.title,
        component: WizardStep,
        props: {
          config: stepConfig,
        },
      });
      const buildCustomStep = (title, component, props = {}) => ({
        title,
        component,
        props,
      });
      const addRefs = (stepItem, idx) => ({
        ...stepItem,
        props: {
          ...stepItem.props,
          ref: this.getStepRefName(idx),
        },
      });
      const stepList = [
        buildFormStep(WizardConfig.contactInfoStep),
        buildCustomStep("Servant's Children", VolunteerGuestsStep),
        buildFormStep(WizardConfig.additionalInfoStep),
        buildCustomStep("Review and Pay", ReviewAndPay),
      ];
      return stepList.map(addRefs);
    },
  },

  methods: {
    getStepRefName(idx) {
      return `step${idx}`;
    },

    handleForwardNavigation(nextAction) {
      const currentStep = this.$refs[this.getStepRefName(this.activeStep)];
      if (currentStep.validateStep(this.$store.state.volunteer)) {
        if (!this.rulesAccepted && this.activeStep === this.steps.length - 2) {
          this.displayRulesModal(nextAction);
        } else {
          nextAction();
        }
      } else {
        this.notifyError("Please fix errors on this page", false);
      }
    },

    displayRulesModal(nextAction) {
      this.$buefy.modal.open({
        parent: this,
        component: RulesModal,
        props: { onClose: nextAction },
        hasModalCard: true,
        trapFocus: true,
        canCancel: false,
      });
    },

    displayCovidDisclaimerModal() {
      this.$buefy.modal.open({
        parent: this,
        component: CovidDisclaimerModal,
        hasModalCard: true,
        trapFocus: true,
        canCancel: false,
      });
    },
  },

  mounted() {
  }
};
</script>

<style scoped>
.step-container {
  max-width: 800px;
  margin: auto;
}
</style>