<template>
  <section>
    <b-field label="" class="is-left">
      <b-select
        v-model="filter"
        placeholder="Select a filter"
        icon="filter-variant"
      >
        <option v-for="option in filterOptions" :value="option" :key="option">
          {{ option }}
        </option>
      </b-select>
      <b-button
        class="button is-ghost"
        size="medium"
        rounded
        icon-left="close-circle"
        title="Clear filter"
        @click="filter = null"
      ></b-button>
    </b-field>

    <p class="has-text-centered">
      Showing {{ filteredRegistrations.length }} of
      {{ registrations.length }} registrations
    </p>

    <b-table
      :data="filteredRegistrations"
      :bordered="false"
      :striped="true"
      :narrowed="false"
      :hoverable="true"
      :loading="false"
      :focusable="false"
      :mobile-cards="true"
    >
      <b-table-column label="Actions" v-slot="props">
        <b-dropdown aria-role="list" class="is-clickable">
          <template #trigger>
            <b-icon icon="dots-vertical"></b-icon>
          </template>

          <b-dropdown-item aria-role="listitem" @click="launchDetailsModal(props.row)">
            <p class="is-flex is-align-content-center">
              <b-icon icon="eye-outline"></b-icon>
              <span class="ml-1">View Registration Details</span>
            </p>
          </b-dropdown-item>

          <b-dropdown-item aria-role="listitem" v-if="hasRoomComments(props.row)"
            @click="launchRoomCommentsDialog(props.row)">
            <p class="is-flex is-align-content-center">
              <b-icon icon="comment-text-outline"></b-icon>
              <span class="ml-1">View Room Special Requests</span>
            </p>
          </b-dropdown-item>
          
        </b-dropdown>
      </b-table-column>

      <b-table-column field="angel_name" label="Angel" v-slot="props">
        {{ getName(props.row, "angels") }}
      </b-table-column>

      <b-table-column field="parent" label="Parent" v-slot="props">
        {{ getParentName(props.row) }}
      </b-table-column>

      <b-table-column field="primaryContactNumber" label="Phone" v-slot="props">
        {{ props.row.contactInfo.primaryContactNumber }}
      </b-table-column>

      <b-table-column field="roomCount" label="Rooms" numeric v-slot="props">
        {{ props.row.room.roomCount }}
      </b-table-column>

      <b-table-column
        field="familyMembers"
        label="People"
        numeric
        v-slot="props"
      >
        {{ props.row.familyMembers.length }}
      </b-table-column>

      <b-table-column field="total" label="Total" v-slot="props">
        {{ formatAmount(props.row.total) }}
      </b-table-column>

      <b-table-column field="payment" label="Payment" v-slot="props">
        {{ formatAmount(getPaymentAmount(props.row)) }}
      </b-table-column>

      <b-table-column field="discountAmount" label="Discount" v-slot="props">
        {{ formatAmount(props.row.discountAmount || 0) }}
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">No registrations</div>
      </template>
    </b-table>
  </section>
</template>

<script>
import { formatCurrency } from "@/utils/number";
import AdminEventAttendeesModal from "./AdminEventAttendeesModal.vue";

export default {
  name: "AdminEventAttendees",

  props: {
    registrations: Array,
  },

  data() {
    return {
      filter: null,
    };
  },

  computed: {
    filterOptions() {
      return [
        "Transportation needed",
        "Used discount code",
        "Room special requests",
      ];
    },

    filteredRegistrations() {
      let filtered = this.registrations;

      if (this.filter === "Transportation needed") {
        filtered = this.registrations.filter(
          (reg) => reg.isTransportationNeeded
        );
      } else if (this.filter === "Used discount code") {
        filtered = this.registrations.filter((reg) => reg.discountCode != null);
      } else if (this.filter === "Room special requests") {
        filtered = this.registrations.filter(
          (reg) => this.hasRoomComments(reg)
        );
      }

      return filtered;
    },
  },

  methods: {
    getName(row, familyMemberType) {
      const familyMember = row.familyMembers.find(
        (item) => item.type === familyMemberType
      );
      return familyMember
        ? `${familyMember.firstName} ${familyMember.lastName}`
        : "";
    },

    getParentName(row) {
      return this.getName(row, "father") || this.getName(row, "mother");
    },

    getPaymentAmount(row) {
      if (row.paymentInfo && row.paymentInfo.amount) {
        return parseFloat(row.paymentInfo.amount);
      }
      return 0;
    },

    hasRoomComments(row) {
      return row.room.roomComments && row.room.roomComments.trim().length
    },

    formatAmount(value) {
      return formatCurrency(value);
    },

    launchDetailsModal(row) {
      this.$buefy.modal.open({
        parent: this,
        component: AdminEventAttendeesModal,
        props: { registration: row },
        hasModalCard: true,
        trapFocus: true,
        canCancel: true,
        fullScreen: true,
      });
    },

    launchRoomCommentsDialog(row) {
      this.$buefy.dialog.alert({
        title: "Room Special Requests",
        message: row.room.roomComments,
        confirmText: "Close",
      });
    },
  },
};
</script>

<style scoped>
</style>