<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Registration Details</p>
    </header>
    <section class="modal-card-body">
      <div>
        <p class="subtitle has-background-info-dark has-text-white p-1 mt-4">
          General
        </p>

        <p class="is-flex mt-2">
          <strong class="data-label">ID:</strong>
          <span>{{ registration.id }}</span>
        </p>

        <p class="is-flex mt-2">
          <strong class="data-label">Date:</strong>
          <span>{{ formatDateTime(registration.registeredAt) }}</span>
        </p>
      </div>

      <div>
        <p class="subtitle has-background-info-dark has-text-white p-1 mt-4">
          Payment Info
        </p>

        <p class="is-flex mt-2">
          <strong class="data-label">Total:</strong>
          <span>{{ formatAmount(registration.total) }}</span>
        </p>

        <p class="is-flex mt-2">
          <strong class="data-label">Payment:</strong>
          <span>{{ paymentAmount }}</span>
        </p>

        <p class="is-flex mt-2">
          <strong class="data-label">Discount:</strong>
          <span>{{ formatAmount(registration.discountAmount || 0) }}</span>
        </p>

        <p class="is-flex mt-2">
          <strong class="data-label">Discount Code:</strong>
          <span>{{ registration.discountCode || "" }}</span>
        </p>
      </div>

      <div>
        <p class="subtitle has-background-info-dark has-text-white p-1 mt-4">
          Priest / Recommendation Info
        </p>

        <p class="is-flex mt-2">
          <strong class="data-label">Priest Name:</strong>
          <span>{{
            registration.priestName ||
            `${registration.priestFirstName} ${registration.priestLastName}`
          }}</span>
        </p>

        <p class="is-flex mt-2">
          <strong class="data-label">Priest Email:</strong>
          <span>{{ registration.priestEmail }}</span>
        </p>

        <p v-if="registration.priestRecommendationStatus" class="is-flex mt-2">
          <strong class="data-label">Recommendation:</strong>
          <b-tag
            :type="getStatusTagType(registration.priestRecommendationStatus)"
            >{{ registration.priestRecommendationStatus }}</b-tag
          >
        </p>

        <p v-if="registration.priestRecommendationTimestamp" class="is-flex mt-2">
          <strong class="data-label">Comments:</strong>
          <span>{{ registration.priestRecommendationComments }}</span>
        </p>

        <p v-if="registration.priestRecommendationTimestamp" class="is-flex mt-2">
          <strong class="data-label">Response Date:</strong>
          <span>{{ formatDateTime(registration.priestRecommendationTimestamp) }}</span>
        </p>
      </div>

      <div v-for="(section, idx) in sections" :key="idx">
        <p class="subtitle has-background-info-dark has-text-white p-1 mt-4">
          {{ section.title }}
        </p>

        <p
          v-for="field in section.fields"
          :key="field.name"
          class="is-flex mt-2"
        >
          <strong class="data-label">{{ getFieldLabel(field) }}:</strong>
          <span>{{ getFieldValue(field, section.parentField) }}</span>
        </p>
      </div>

      <p class="subtitle has-background-info-dark has-text-white p-1 mt-4">Children</p>

      <div
        v-for="child in registration.guests"
        :key="child.key"
      >
        <p class="subtitle has-background-success-dark has-text-white p-1 mt-4">
          {{ getChildTitle(child) }}
        </p>
        <p
          v-for="field in getChildFields(child)"
          :key="field.name"
          class="is-flex mt-2"
        >
          <strong class="data-label">{{ getFieldLabel(field) }}:</strong>
          <span>{{ getChildFieldValue(field, child) }}</span>
        </p>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button label="Close" @click="$emit('close')" />
    </footer>
  </div>
</template>

<script>
import startCase from "lodash/startCase";
import get from "lodash/get";
import { formatCurrency } from "@/utils/number";
import { formatFullDateTime, formatFullDate } from "@/utils/dateandtime";
import WizardConfig from "@/config/VolunteerWizard";
import VolunteerGuestsConfig from "@/config/VolunteerGuests";

export default {
  name: "AdminEventVolunteersModal",

  props: {
    registration: Object,
  },

  computed: {
    paymentAmount() {
      let amount = 0;
      if (
        this.registration.paymentInfo &&
        this.registration.paymentInfo.amount
      ) {
        amount = parseFloat(this.registration.paymentInfo.amount);
      }
      return formatCurrency(amount);
    },

    sections() {
      return [
        ...WizardConfig.contactInfoStep.sections,
        ...WizardConfig.additionalInfoStep.sections,
      ].filter((s) => s.title !== "Priest Info");
    },
  },

  methods: {
    formatAmount(value) {
      return formatCurrency(value);
    },

    formatDateTime(value) {
      return formatFullDateTime(value);
    },

    getFieldKey(prop, parentProp) {
      return parentProp ? `${parentProp}.${prop}` : prop;
    },

    getFieldValue(field, parentProp) {
      const fieldKey = this.getFieldKey(field.name, parentProp);
      const value = get(this.registration, fieldKey);
      return this.transformValue(value, field);
    },

    transformValue(value, field) {
      if (field.type === "multi-select") {
        return value ? value.join(", ") : "";
      } else if (field.type === "switch") {
        return value ? "Yes" : "No";
      } else if (field.type === "date") {
        return value ? formatFullDate(value) : "";
      }
      return value;
    },

    getFieldLabel(field) {
      return field.label || startCase(field.name);
    },

    getStatusTagType(status) {
      let type = "";
      switch (status) {
        case "Approved":
          type = "is-success";
          break;
        case "Denied":
          type = "is-danger";
          break;
        case "Pending":
          type = "is-warning";
          break;

        default:
          break;
      }
      return type;
    },

    getChildFieldValue(field, child) {
      const value = get(child, field.name);
      return this.transformValue(value, field);
    },

    getChildTitle(child) {
      const label = 'Child'
      const firstName = child.firstName || "";

      return `${label} - ${firstName}`;
    },

    getChildFields() {
      return VolunteerGuestsConfig.commonFields;
    },
  },
};
</script>

<style scoped>
.data-label {
  flex-basis: 170px;
  /* text-align: right; */
  margin-right: 10px;
}
</style>