<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Confirmation</p>
    </header>
    <section class="modal-card-body">
      <p class="subtitle is-5 is-spaced">
        Resend priest recommendation email for <strong>{{ volunteerName }}</strong>?
      </p>
    </section>
    <footer class="modal-card-foot">
      <b-button label="Cancel" @click="$emit('close')" />
      <b-button label="Send" type="is-primary" :loading="sendingEmail" @click="handleSend" />
    </footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import mixins from "@/components/mixins";

// @ is an alias to /src
import { logError } from "@/utils/logger";
import actions from "@/utils/constants/action_types";

export default {
  name: "AdminEventVolunteerRecommendationEmailModal",

  mixins: [mixins],

  props: {
    registration: Object,
    convention: Object,
  },

  data() {
    return {
      sendingEmail: false,
    };
  },

  computed: {
    volunteerName() {
      return `${this.registration.firstName} ${this.registration.lastName}`;
    },
  },

  methods: {
    ...mapActions("admin", [
      actions.admin.RESEND_VOLUNTEER_RECOMMENDATION_EMAIL,
    ]),

    handleSend() {
      this.sendingEmail = true;
      this[actions.admin.RESEND_VOLUNTEER_RECOMMENDATION_EMAIL]({
        conventionId: this.convention.id,
        registrationId: this.registration.id,
      })
        .then(() => {
          this.sendingEmail = false;
          this.$emit("close");
          this.notifySuccess("Recommendation email sent successfully!")
        })
        .catch((err) => {
          const message = `Failed to send recommendation email. ${err?.message}`;
          logError(message, err);
          this.sendingEmail = false;
          this.$emit("close");
          this.notifyError(message);
        });
    },
  },
};
</script>
