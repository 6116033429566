<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Convention Rules</p>
    </header>
    <section class="modal-card-body">
      <p class="block">
        We are looking forward to spending these spiritual, blessed, and fun
        days together. We ask that you remember our actions reflect our church
        and diocese and the impression we make as Coptic Christians.
      </p>
      <p class="block">
        We kindly ask you to please adhere to the following
        <strong>dress codes</strong>:
      </p>
      <ol class="block ml-5">
        <li>Attire should be modest and respectful</li>
        <li>Shorts knee length</li>
        <li>No cut shirts/tops/dresses</li>
        <li>Swim shorts/swim shirts</li>
        <li>No speedos or bikinis</li>
        <li>Knee length skirts/dresses</li>
        <li>Children: one piece swim suits</li>
        <li>Parent night - dress up attire</li>
      </ol>

      <p class="block">
        The program has been designed to give each one of us a spiritual benefit
        and enjoyment please encourage your kids to attend so they receive the
        full benefit of the convention.
      </p>
      <p class="block">
        Please do not forget to bring your spiritual books, Agpeya, Bible,
        Liturgy Book, and Pslamody.
      </p>
      <p class="block">
        <strong>Please bring your own towels. The retreat will not supply towels.</strong>
      </p>
      <p class="block">
        Remember the Convention and the Committee in your Prayers.
      </p>
    </section>
    <footer class="modal-card-foot">
      <b-button label="I Agree" type="is-success" @click="handleAgree" />
    </footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import actions from "@/utils/constants/action_types";

export default {
  name: "RulesModal",

  props: {
    onClose: {
      type: Function,
      required: false,
    },
  },

  methods: {
    ...mapActions([actions.ACCEPT_RULES]),

    handleAgree() {
      this[actions.ACCEPT_RULES]();
      this.$emit("close");
      if (this.onClose && typeof this.onClose === "function") {
        this.onClose();
      }
    },
  },
};
</script>