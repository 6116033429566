import actions from "@/utils/constants/action_types";
import mutations from "@/utils/constants/mutation_types";

import { login, logout, resetPassword } from "@/service/firebase";
import { log } from "@/utils/logger";
import { getConventions, getRegistrations, exportRegistrations, resendVolunteerRecommendationEmail } from "@/admin-api";

const scopedActions = actions.admin;
const scopedMutations = mutations.admin;

const state = () => ({
    auth: {
        user: null,
    },
    conventions: [],
    registrations: {},
});

const getterHandlers = {};

const actionHandlers = {
    // eslint-disable-next-line no-unused-vars
    [scopedActions.HANDLE_LOGIN](context, payload) {
        const { email, password } = payload;
        return login(email, password);
    },

    // eslint-disable-next-line no-unused-vars
    [scopedActions.HANDLE_LOGOUT](context) {
        return logout();
    },

    // eslint-disable-next-line no-unused-vars
    [scopedActions.HANDLE_RESET_PASSWORD](context, payload) {
        const { email } = payload;
        return resetPassword(email);
    },

    async [scopedActions.HANDLE_AUTH_CHANGE]({ commit }, payload) {
        log('auth changed action handler. User exists:', !!payload.user);
        commit(scopedMutations.AUTH_CHANGED, payload);
    },

    async [scopedActions.FETCH_ALL_CONVENTIONS]({ commit }) {
        const conventions = await getConventions();
        commit(scopedMutations.CONVENTIONS_FETCHED, { conventions });
    },

    async [scopedActions.FETCH_REGISTRATIONS]({ commit }, payload) {
        const { conventionId } = payload;
        const registrations = await getRegistrations(conventionId);
        commit(scopedMutations.REGISTRATIONS_FETCHED, { registrations, conventionId });
    },

    // eslint-disable-next-line no-unused-vars
    [scopedActions.EXPORT_REGISTRATIONS](context, payload) {
        const { conventionId } = payload;
        return exportRegistrations(conventionId);
    },

    // eslint-disable-next-line no-unused-vars
    async [scopedActions.RESEND_VOLUNTEER_RECOMMENDATION_EMAIL](context, payload) {
        const { conventionId, registrationId } = payload;
        return resendVolunteerRecommendationEmail(conventionId, registrationId);
    },
};

const mutationHandlers = {
    [scopedMutations.AUTH_CHANGED](state, payload) {
        const { user } = payload;
        state.auth.user = user ? { uid: user.uid, displayName: user.displayName } : null;
    },

    [scopedMutations.CONVENTIONS_FETCHED](state, payload) {
        const { conventions } = payload;
        state.conventions = conventions;
    },

    [scopedMutations.REGISTRATIONS_FETCHED](state, payload) {
        const { registrations, conventionId } = payload;
        const mergeObj = { [conventionId]: registrations };
        state.registrations = { ...state.registrations, ...mergeObj };
    },
};

export default {
    namespaced: true,
    state,
    getters: getterHandlers,
    actions: actionHandlers,
    mutations: mutationHandlers
}