import { TShirtSizes, USStates, CanadianProvinces, USChurches, CanadianChurches, OtherChurches } from "@/utils/constants";

const required = true;

const config = {
    contactInfoStep: {
        title: "Contact Info",
        sections: [
            {
                title: "Registration Info",
                fields: [
                    {
                        name: "firstName",
                        type: "text",
                        required,
                    },
                    {
                        name: "lastName",
                        type: "text",
                        required,
                    },
                    {
                        name: "birthday",
                        type: "date",
                        disableFutureDates: true,
                        required,
                        over18: true,
                    },
                    {
                        name: "tshirtSize",
                        label: "T-Shirt Size",
                        type: "select",
                        required,
                        optionList: [
                            {
                                group: "Men",
                                items: TShirtSizes.mens
                            },
                            {
                                group: "Women",
                                items: TShirtSizes.womens
                            },
                            {
                                group: "Youth",
                                items: TShirtSizes.youth
                            },
                        ]
                    },
                    {
                        name: "roomType",
                        type: "select",
                        required,
                        optionList: [
                            {
                                group: "Select one",
                                items: [
                                    {
                                        value: "shared",
                                        label: "Shared"
                                    },
                                    {
                                        value: "private",
                                        label: "Private"
                                    }
                                ]
                            },
                        ],
                        infoText: "A shared room accommodates two volunteers."
                    },
                    {
                        name: "roomComments",
                        label: "Room Special Requests",
                        type: "textarea"
                    },
                    {
                        name: "arrivalDate",
                        label: "Arriving On (if partial stay)",
                        type: "date",
                    },
                    {
                        name: "departureDate",
                        label: "Departing On (if partial stay)",
                        type: "date",
                    },
                ]
            },
            {
                title: "Contact Info",
                parentField: "contactInfo",
                fields: [
                    {
                        name: "emailAddress",
                        type: "email",
                        required,
                        placeholder: "example@example.com",
                    },
                    {
                        name: "primaryContactNumber",
                        type: "phone",
                        required,
                        placeholder: "555-555-5555",
                    },
                    {
                        name: "secondaryContactNumber",
                        type: "phone",
                        placeholder: "555-555-5555",
                    },
                    {
                        name: "addressLine1",
                        type: "text",
                        required
                    },
                    {
                        name: "addressLine2",
                        type: "text",
                    },
                    {
                        name: "city",
                        type: "text",
                        required
                    },
                    {
                        name: "state",
                        label: "State / Province",
                        type: "select",
                        required,
                        optionList: [
                            {
                                group: "United States",
                                items: USStates
                            },
                            {
                                group: "Canada",
                                items: CanadianProvinces
                            },
                        ]
                    },
                    {
                        name: "zipCode",
                        type: "number",
                        placeholder: "12345",
                        required
                    },
                ]
            },
            {
                title: "Emergency Info",
                parentField: "emergencyInfo",
                fields: [
                    {
                        name: "emergencyContact",
                        type: "text",
                        required,
                        placeholder: "First Last",
                    },
                    {
                        name: "emergencyPhone",
                        type: "phone",
                        required,
                        placeholder: "555-555-5555",
                    },
                ]
            },
        ]
    },
    additionalInfoStep: {
        title: "Additional Info",
        sections: [
            {
                title: "Priest Info",
                fields: [
                    {
                        name: "priestId",
                        label: "Priest",
                        type: "select",
                        required,
                        refDataKey: "priestNames",
                        refDataValueProp: "id",
                        refDataLabelProp: "name",
                        refDataGroupTitle: "SUS Diocese Priests",
                        infoText: `In order to volunteer at this convention you will need a letter
                            of recommendation from your local priest or father of confession. Please select a
                            priest from the list below and we will contact him with your information.`
                    },
                ]
            },
            {
                title: "Church",
                fields: [
                    {
                        name: "church",
                        type: "select",
                        required,
                        optionList: [
                            {
                                group: "United States",
                                items: USChurches
                            },
                            {
                                group: "Canada",
                                items: CanadianChurches
                            },
                            {
                                group: "Other",
                                items: OtherChurches
                            },
                        ]
                    },
                ]
            },
            {
                title: "Donation",
                fields: [
                    {
                        name: "donationAmount",
                        type: "counter",
                        min: 0,
                        step: 1,
                        editable: true,
                        infoText: "If you would like to make a donation with your registration, please enter an amount below.",
                    },
                ]
            },
            {
                title: "Transportation",
                fields: [
                    {
                        name: "isTransportationNeeded",
                        label: "I need aiport transportation",
                        type: "switch",
                        infoText: "After registering, please send your information to info@copticangel.org to arrange transportation.",
                    },
                ]
            },
            {
                title: "Medical Info",
                fields: [
                    {
                        name: "medicalInformation",
                        type: "textarea",
                        infoText: `Please provide us with any medical conditions you may have and any medications you must take,
                            and any allergies you may have, so that we may be better informed in the event of a medical emergency.
                            Please be assured that this information will be held in strict confidence and will not be
                            accessed unless medically necessary.`,
                    },
                ]
            }
        ]
    }
};

export default config;