import { diffYears } from './dateandtime';

const EMAIL_RE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PHONE_RE = /^\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/;

export function validateEmail(value) {
    return EMAIL_RE.test(value);
}

export function validatePhone(value) {
    return PHONE_RE.test(value);
}

export function validateOver18(value) {
    return diffYears(value, new Date()) >= 18;
}

export function validateRequired(value) {
    if (typeof value == 'string') {
        return value && value.trim().length > 0;
    }

    if (typeof value == 'number') {
        return value != null && value != undefined && value > 0;
    }

    if (value instanceof Array) {
        return value && value.length > 0;
    }

    return !!value;
}

export function validateField(fieldConfig, value) {
    let error = null;

    const hasValue = validateRequired(value);

    if (fieldConfig?.required && !hasValue) {
        error = ['select', 'multi-select'].includes(fieldConfig?.type) ? 'Please select a value' : 'Please enter a value';
    }
    else if (hasValue) {
        if (fieldConfig?.type === 'email' && !validateEmail(value)) {
            error = 'Please enter a valid email';
        }

        if (fieldConfig?.type === 'phone' && !validatePhone(value)) {
            error = 'Please enter a valid phone number';
        }

        if (fieldConfig?.type === 'date' && fieldConfig?.over18 && !validateOver18(value)) {
            error = 'You must be at least 18 years of age to continue'; 
        }
    }

    return error;
}
