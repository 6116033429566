import generateRandomKey from "@/utils/generateRandomKey";
import actions from "@/utils/constants/action_types";
import mutations from "@/utils/constants/mutation_types";

const scopedActions = actions.volunteer;
const scopedMutations = mutations.volunteer;

const state = () => ({
    isVolunteer: true,
    roomType: '',
    firstName: '',
    lastName: '',
    tshirtSize: '',
    roomComments: '',
    contactInfo: {
        emailAddress: '',
        primaryContactNumber: '',
        secondaryContactNumber: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: ''
    },
    emergencyInfo: {
        emergencyContact: '',
        emergencyPhone: ''
    },
    guests: [],
    guestsInitialized: false,
    priestId: '',
    church: '',
    donationAmount: 0,
    isTransportationNeeded: false,
    discountCode: null,
});

const getterHandlers = {};

const actionHandlers = {
    [scopedActions.SET_DISCOUNT_CODE]({ commit }, payload) {
        commit(scopedMutations.DISCOUNT_CODE_CHANGED, payload);
    },

    [scopedActions.UPDATE_FORM_FIELD]({ commit }, payload) {
        commit(scopedMutations.FORM_FIELD_CHANGED, payload);
    },

    [scopedActions.INITIALIZE_GUESTS]({ commit }, payload) {
        const count = payload['guestCount'] < 0 ? 0 : payload['guestCount'];
        for (let i = 0; i < count; i++) {
            commit(scopedMutations.GUEST_ADDED);
        }
        commit(scopedMutations.GUESTS_INITIALIZED);
    },

    [scopedActions.ADD_GUEST]({ commit }, payload) {
        commit(scopedMutations.GUEST_ADDED, payload);
    },

    [scopedActions.REMOVE_GUEST]({ commit }, payload) {
        commit(scopedMutations.GUEST_REMOVED, payload);
    },

    [scopedActions.UPDATE_GUEST_FIELD]({ commit }, payload) {
        commit(scopedMutations.GUEST_FIELD_CHANGED, payload);
    },
};

const mutationHandlers = {
    [scopedMutations.DISCOUNT_CODE_CHANGED](state, payload) {
        const { discountCode } = payload;
        state.discountCode = discountCode;
    },

    [scopedMutations.FORM_FIELD_CHANGED](state, payload) {
        const { prop, value, parentProp } = payload;
        if (parentProp) {
            state[parentProp][prop] = value;
        } else {
            state[prop] = value;
        }
    },

    [scopedMutations.GUESTS_INITIALIZED](state) {
        state.guestsInitialized = true;
    },

    [scopedMutations.GUEST_ADDED](state) {
        const newGuest = {
            key: generateRandomKey()
        };
        state.guests = [...state.guests, newGuest];
        if (state.roomType == 'shared') {
            state.roomType = 'private';
            state.originalRoomType = 'shared';
        }
    },

    [scopedMutations.GUEST_REMOVED](state, payload) {
        const { key } = payload;
        const updatedGuests = [...state.guests];
        const idx = updatedGuests.findIndex((m) => m.key === key);
        if (idx > -1) {
            updatedGuests.splice(idx, 1);
        }
        state.guests = updatedGuests;
        if (state.guests.length == 0 && state.originalRoomType == 'shared') {
            state.roomType = 'shared';
        }
    },

    [scopedMutations.GUEST_FIELD_CHANGED](state, payload) {
        const { prop, value, guest } = payload;
        const updatedGuests = [...state.guests];
        const idx = updatedGuests.findIndex((m) => m.key === guest.key);
        updatedGuests[idx] = { ...updatedGuests[idx], [prop]: value };
        state.guests = updatedGuests;
    },
};

export default {
    namespaced: true,
    state,
    getters: getterHandlers,
    actions: actionHandlers,
    mutations: mutationHandlers
}