<template>
  <div>
    <article v-for="(section, idx) in config.sections" :key="idx" class="message is-primary">
      <div class="message-header">
        {{ section.title }}
      </div>
      <div class="message-body has-background-white">
        <form-field v-for="field in section.fields" :key="field.name" :field="prepareFieldConfig(field)"
          :value="getFieldValue(field.name, section.parentField)"
          :fieldError="getFieldError(field.name, section.parentField)" @input="
            (value) => handleFieldChange(value, field.name, section.parentField)
          " />
      </div>
    </article>
  </div>
</template>

<script>
import get from "lodash/get";
import { validateField } from "@/utils/validators";
import { log } from "@/utils/logger";
import actions from "@/utils/constants/action_types";

import FormField from "./FormField.vue";

export default {
  name: "WizardStep",

  components: {
    FormField,
  },

  inject: ["namespace"],

  props: {
    config: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      errors: [],
    };
  },

  mounted() {
    log('WizardStep mounted with title', JSON.stringify(this.config.title));
  },

  methods: {
    validateStep(state) {
      const allStepFields = this.config.sections.flatMap((section) => {
        return section.fields.map((field) => {
          const value = section.parentField
            ? state[section.parentField][field.name]
            : state[field.name];
          return {
            prop: field.name,
            value,
            parentProp: section.parentField,
          };
        });
      });

      allStepFields.forEach((f) => this.handleFieldValiation(f));
      return this.errors.length === 0;
    },

    handleFieldValiation({ prop, value, parentProp }) {
      const errorKey = this.getFieldKey(prop, parentProp);
      this.clearError(errorKey);
      const fieldConfig = this.getFieldConfig(prop, parentProp);
      const error = validateField(fieldConfig, value);
      if (error) {
        this.errors.push({ key: errorKey, message: error });
      }
    },

    handleFieldChange(value, prop, parentProp) {
      if (typeof value == "string") {
        value = value.trim();
      }

      const payload = {
        prop,
        value,
        parentProp,
      };
      const actionType = `${this.namespace}/${actions[this.namespace].UPDATE_FORM_FIELD}`;
      this.$store.dispatch(actionType, payload);
      this.handleFieldValiation(payload);
    },

    prepareFieldConfig(field) {
      if (field.type === "select" && field.refDataKey) {
        const refData = this.$store.state.global.refData[field.refDataKey];
        const items = refData.map(item => ({
          value: item[field.refDataValueProp],
          label: item[field.refDataLabelProp]
        }));
        field.optionList = [
          {
            group: field.refDataGroupTitle || "Select one",
            items
          }
        ]
      }
      return field;
    },

    getFieldConfig(prop, parentProp) {
      const sectionFilter = (s) => {
        return parentProp ? s.parentField === parentProp : !s.parentField;
      };

      const sectionsToSearch = this.config.sections.filter(sectionFilter);
      const availableFields = sectionsToSearch.flatMap((s) => s.fields);
      const field = availableFields.find((f) => f.name === prop);
      return field;
    },

    getFieldKey(prop, parentProp) {
      return parentProp ? `${parentProp}.${prop}` : prop;
    },

    getFieldValue(prop, parentProp) {
      const fieldKey = this.getFieldKey(prop, parentProp);
      const scopedState = get(this.$store.state, this.namespace);
      return get(scopedState, fieldKey);
    },

    getFieldError(prop, parentProp) {
      const fieldKey = this.getFieldKey(prop, parentProp);
      const error = this.errors.find((e) => e.key === fieldKey);
      return error?.message;
    },

    clearError(key) {
      const idx = this.errors.findIndex((e) => e.key === key);
      if (idx > -1) {
        this.errors.splice(idx, 1);
      }
    },
  },
};
</script>